<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <el-table
          :header-cell-style="{
            background: '#f8f8f8',
            height: '40px',
          }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="商品" width="360">
            <template slot-scope="scope">
              <div class="shopbox">
                <img :src="scope.row.image" alt="" />
                <div>
                  <p>订单号:{{ scope.row.outTradeNo }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="payPrice" label="价格" width="200">
          </el-table-column>
          <el-table-column label="操作状态" width="120">
            <template slot-scope="data">
              <div>
                <el-button
                  v-if="data.row.status == 0"
                  type="text"
                  size="mini"
                  @click="pay(data.row)"
                  >立即支付</el-button
                >
                <el-button
                  v-if="data.row.status == 0"
                  type="one"
                  size="mini"
                  @click="updat(data.row)"
                  >取消订单</el-button
                >
                <el-button type="one" size="mini">{{
                  data.row.status == 0
                    ? "待支付"
                    : data.row.status == 1
                    ? "已支付"
                    : "已取消"
                }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page container">
          <el-pagination
            background
            :page-size="8"
            :pager-count="11"
            layout="prev, pager, next"
            :total="totalCount"
            :current-page="currpage"
            @current-change="pagechange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Otherfooter />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import { getUser } from "@/plugins/auth.js";

import Aside from "@/components/Aside.vue";
import Otherfooter from "@/components/Otherfooter.vue";
import { api_queryResumeOrderList, api_updateHrOrder } from "@/apis/apis.js";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Otherfooter,
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      totalCount: 1,
      currpage: 1,
    };
  },
  created() {
    this.getorderlist();
  },
  methods: {
    // 为表格行添加样式
    // tableRowClassName() {
    //   return "single"; //  偶数行对应的类
    // },

    getorderlist() {
      api_queryResumeOrderList({
        userId: getUser().id,
        currPage: this.currpage,
        ppageSize: 8,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.data.dataList;
        this.totalCount = res.data.data.totalCount;
      });
    },

    //立即支付
    pay(res) {
      console.log(res);
      this.$router.push({ path: `/orderdeil/${res.id}` });
    },
    //取消订单
    updat(res) {
      console.log(res);
      api_updateHrOrder({
        id: res.id,
        status: 2,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "取消成功",
          });
          this.getorderlist();
        }
      });
    },
    pagechange(page) {
      this.currpage = page;
      this.getorderlist();
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    flex: 1;
    width: 960px;
    border: 1px solid #f2f2f2;
    padding: 20px;
    // /deep/ .tbody {
    //   margin-top: 10px;
    //   background-color: red;
    // }
    // /deep/ .el-table__body {
    //   border-collapse: separate;
    //   border-spacing: 0 8px;
    //   table-layout: auto !important;
    // }
    // /deep/ .el-table__row {
    //   margin-top: 20px !important;
    //   -background-color: rgb(0, 255, 34);
    //   border: 1px solid red !important ;
    .shopbox {
      display: flex;

      img {
        width: 80px;
        height: 80px;
        margin-right: 14px;
      }
    }
    // }
  }
}
.page {
  margin-top: 40px;
  text-align: center;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5751fb !important;
  color: #fff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #5751fb !important;
}
.el-button--one {
  border: 0;
  color: rgb(150, 149, 149);
}
</style>